<template>
  <div class="medicalOrders h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.medical_orders.Label }}
      </h3>
      <div
        class="
          row row-wrapper
          d-flex
          align-items-center
          justify-content-center
          pb-5
        "
      >
        <div
          class="row py-5"
          v-if="this.$route.name == 'step14'"
          data-aos="zoom-out"
          data-aos-easing="ease-in-out"
          data-aos-duration="600"
        >
          <!-- <div class="row">
            <div class="col-3 py-3 text-center border head"></div>
            <div class="col-3 py-3 text-center border head">
              <h5>Lab Investigation</h5>
            </div>
            <div class="col-3 py-3 text-center border head">
              <h5>Result</h5>
            </div>
            <div class="col-3 py-3 text-center border head">
              <h5>Reference range</h5>
            </div>
          </div> -->
          <div
            class="row"
            v-for="(item, key) in this.medical_orders.body"
            :key="item.id"
          >
            <div class="col-2 border-right border-bottom">
              <h5>{{ key }}</h5>
            </div>
            <div class="col-10 ">
              <div class="row">
                <div class="col-4 py-3 text-center border">
                  <p class="text-muted" v-for="val in item" :key="val.id">
                    {{ val.test_name }}
                  </p>
                </div>
                <div class="col-4 py-3 text-center border">
                  <p class="text-muted" v-for="val in item" :key="val.id">
                    {{ val.result }}
                  </p>
                </div>
                <div class="col-4 py-3 text-center border">
                  <p class="text-muted" v-for="val in item" :key="val.id">
                    {{ val.reference_range }}
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div
          class="row py-5"
          v-if="this.$route.name == 'step15'"
          data-aos="zoom-out"
          data-aos-easing="ease-in-out"
          data-aos-duration="600"
        >
          <!-- <div class="row">
            <div class="col-6 py-3 text-center border head">
              <h5>Diagnostic Procedure</h5>
            </div>
            <div class="col-6 py-3 text-center border head">
              <h5>Findings</h5>
            </div>
          </div> -->
          <div
            class="row"
            v-for="(item, key) in this.medical_orders.body"
            :key="key.id"
          >
            <div :class="key == 0 ? 'col-6 py-3 text-center border head' : 'col-6 py-3 text-center border'">
              <p :class="key == 0 ? '' : 'text-muted'">
                {{ item.diagnostic_procedure }}
              </p>
            </div>
            <div :class="key == 0 ? 'col-6 py-3 text-center border head' : 'col-6 py-3 text-center border'">
              <p :class="key == 0 ? '' : 'text-muted'">
                {{ item.findings }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="row py-5"
          v-if="this.$route.name == 'step16'"
          data-aos="zoom-out"
          data-aos-easing="ease-in-out"
          data-aos-duration="600"
        >
          <div class="card shadow">
            <div class="card-body p-3 p-md-5">
              <!-- <h5 class="mb-3">Instructions</h5> -->
              <!-- <ul v-if="this.medical_orders.body.isArray">
                <li
                  v-for="(item, key) in this.medical_orders.body"
                  :key="key.id"
                >
                  <p class="text-muted">
                    {{ item.value }}
                  </p>
                </li>
              </ul> -->
              <div>
                  <span
                    v-html="this.medical_orders.body"
                  >
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row py-5"
          v-if="this.$route.name == 'step17'"
          data-aos="zoom-out"
          data-aos-easing="ease-in-out"
          data-aos-duration="600"
        >
          <div class="card shadow">
            <div class="card-body p-3 p-md-5">
              <!-- <h5 class="mb-3">Medication</h5> -->
              <!-- <ul v-if="this.medical_orders.body.isArray">
                <li
                  v-for="(item, key) in this.medical_orders.body"
                  :key="key.id"
                >
                  <p class="text-muted">
                    {{ item.value }}
                  </p>
                </li>
              </ul> -->
              <div>
                  <span
                    v-html="this.medical_orders.body"
                  >
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/store";
export default {
  name: "Medical Orders",
  data() {
    return {
      medical_orders: [],
    };
  },
  updated() {
    if (this.$route.name == "step14") {
      this.medical_orders = this.$store.getters["MOInvestigation"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['MOInvestigation'].instructions )
    } else if (this.$route.name == "step15") {
      this.medical_orders = this.$store.getters["MODiagnosticProcedure"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['MODiagnosticProcedure'].instructions )
    } else if (this.$route.name == "step16") {
      this.medical_orders = this.$store.getters["MOInstructions"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['MOInstructions'].instructions )
    } else if (this.$route.name == "step17") {
      this.medical_orders = this.$store.getters["MOMedication"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['MOMedication'].instructions )
    }
    $("span >>> .table >>> table").css("width", "100% !important");
  },
  mounted() {
    this.medical_orders = this.$store.getters["MOInvestigation"];
    this.$store.dispatch( 'setStartTime');
    // this.$store.dispatch('prevNavigationStatus', false);
  },
  beforeRouteEnter (to, from, next) {
    if (to.name == "step14") {
      if(store.getters["MOInvestigation"].body == null){
        if(from.name.substr(4) > 14){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step15") {
      if(store.getters["MODiagnosticProcedure"].body == null){
        if(from.name.substr(4) > 15){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step16") {
      if(store.getters["MOInstructions"].body == null){
        if(from.name.substr(4) > 16){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step17") {
      if(store.getters["MOMedication"].body == null){
        if(from.name.substr(4) > 17){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } 
  },
  beforeRouteLeave (to, from, next) {
    if (this.$route.name == "step14") {
      this.$store.dispatch( 'setEndTime' , 'Medical Orders Investigation')
    } else if (this.$route.name == "step15") {
      this.$store.dispatch( 'setEndTime' , 'Medical Orders Diagnostic Procedure')
    } else if (this.$route.name == "step16") {
      this.$store.dispatch( 'setEndTime' , 'Medical Orders Instructions')
    } else if (this.$route.name == "step17") {
      this.$store.dispatch( 'setEndTime' , 'Medical Orders Medication')
    }
    // this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
span >>> .table {
  width: 100% !important;
}
span >>> .table >>> table {
  width: 100% !important;
}
.labels {
  background-color: #48d8d8 !important;
  color: #fff;
}
.border-right {
  border-right: 1px solid #ccc;
  background-color: #48d8d8 !important;
  color: #fff;
}
.head {
  background-color: #48d8d8 !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 1.25rem;
}
@media (max-width: 798px) {
  h5 {
    font-size: 0.5rem;
  }
}
</style>